import { gql } from 'graphql-request'

export const PEOPLE_QUERY = gql`
  query People {
    users(group: "staff") {
      id
      username
      firstName
      lastName
      ... on User {
        role
        office {
          title
        }
        team {
          title
        }
        displayImage {
          url @transform(width: 700, immediately: true)
        }
      }
    }
  }
`

export const PERSON_QUERY = (username) => gql`
  query Person {
    user(group: "staff", username: "${username}") {
      id
      fullName
      ... on User {
        role
        office {
          title
        }
        team {
          title
        }
        bio
        displayImage {
          url
        }
      }
    }
  }
`
