import React from 'react'
import { array, func, object } from 'prop-types'

import { AlphabetNav } from '../shared/alphabet-nav'
import { CheckboxListCard } from './checkbox-list-card'

import { useScrollToLetter } from '../../../services/scroll-to'

export const DeckEditList = ({
  data,
  handleSave,
  handleSelection,
  knownIds,
}) => {
  const { scrollToLetterItems, letters, scrollToLetterRef } = useScrollToLetter(
    data
  )

  return (
    <>
      <AlphabetNav letters={letters} />

      <form
        ref={scrollToLetterRef}
        onSubmit={handleSave}
        className="flex flex-wrap -mx-12 md:-mx-24"
      >
        {scrollToLetterItems.map((user) => {
          return (
            <div
              data-letter={user.letter || null}
              className="w-1/2 sm:w-1/4 px-12 mb-24 md:px-24 md:mb-48"
              key={user.id}
            >
              <CheckboxListCard
                user={user}
                onChange={handleSelection}
                checked={knownIds.includes(user.id)}
              />
            </div>
          )
        })}
        <button className="sr-only" type="submit">
          Save Changes
        </button>
      </form>
    </>
  )
}

DeckEditList.propTypes = {
  data: object,
  handleSave: func.isRequired,
  handleSelection: func.isRequired,
  knownIds: array.isRequired,
}
