import React from 'react'

import SEO from '../../../components/seo'
import { AltLinkButton } from '../../../components/button'
import { ListHeading } from '../shared/list-heading'
import { DeckList } from './deck-list'

import CogIcon from '../../../images/svg/cog-icon.inline.svg'
import ArrowRightIcon from '../../../images/svg/arrow-right-icon.inline.svg'
import BackArrowIcon from '../../../images/svg/back-arrow-icon.inline.svg'

import { PEOPLE_QUERY } from '../../../domains/people'
import { ScrollToLetterProvider } from '../../../services/scroll-to'
import { useUserbaseDeckDBState } from '../../../services/userbase/deckDBProvider'
import { Loading } from '../../../components/loading'
import { useAxios } from '../../../services/api'

const ListPage = () => {
  const { deck } = useUserbaseDeckDBState()
  const instance = useAxios()
  const [state, setState] = React.useState({
    data: null,
    loading: true,
    error: null,
  })

  const { data, loading, error } = state

  React.useEffect(() => {
    async function query() {
      try {
        const {
          data: { data },
        } = await instance.request({
          data: PEOPLE_QUERY,
        })

        setState({
          data,
          loading: false,
          error: null,
        })
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          error: error,
        }))
      }
    }

    query()
  }, [instance])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>
  }

  return (
    <section className="max-w-page mx-auto">
      <SEO title="Peruse" />

      <AltLinkButton
        className="fixed flex items-center bottom-0 left-1/2 transform -translate-x-1/2 z-modal mb-24"
        to="/"
      >
        <BackArrowIcon className="block mr-8" />
        <span className="block uppercase leading-loose">Back</span>
      </AltLinkButton>

      <header className="sm:flex sm:justify-between items-center mb-24">
        <ListHeading className="sm-d:mb-12 sm:pr-12">
          {deck.item.name}
        </ListHeading>

        <div className="flex flex-shrink-0 items-center sm:pl-12">
          <AltLinkButton
            className="flex items-center mr-16"
            large
            to="/decks/edit"
          >
            Edit <CogIcon className="ml-10" />
          </AltLinkButton>

          <AltLinkButton className="flex items-center" large to="/decks/study">
            Start <ArrowRightIcon className="ml-10" />
          </AltLinkButton>
        </div>
      </header>

      <ScrollToLetterProvider>
        <DeckList data={data} />
      </ScrollToLetterProvider>
    </section>
  )
}

export default ListPage
