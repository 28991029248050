import React from 'react'
import cx from 'classnames'

import SEO from '../../components/seo'

import { PEOPLE_QUERY } from '../../domains/people'
import { useUserbaseDeckDBState } from '../../services/userbase/deckDBProvider'

import FlipIcon from '../../images/svg/flip-icon.inline.svg'
import PersonIcon from '../../images/svg/person-icon.inline.svg'
import {
  LinkButton,
  IconButton,
  // AltButton
} from '../../components/button'

import ArrowRightIcon from '../../images/svg/long-arrow-right-icon.inline.svg'
import ArrowLeftIcon from '../../images/svg/long-arrow-left-icon.inline.svg'
import CheckIcon from '../../images/svg/check-icon.inline.svg'
import CardsIcon from '../../images/svg/cards-icon.inline.svg'
import AngleLeftIcon from '../../images/svg/angle-left-icon.inline.svg'
import KnownCheckIcon from '../../images/svg/known-check-icon.inline.svg'
import UserFriendsIcon from '../../images/svg/user-friends-icon.inline.svg'
// import RandomIcon from '../../images/svg/random-icon.inline.svg'
// import UndoIcon from '../../images/svg/undo-icon.inline.svg'

import { Link } from 'gatsby'
import { useUserbaseDeckDB } from '../../services/userbase'
import { Loading } from '../../components/loading'
import { useAxios } from '../../services/api'

const DeckCardComponent = ({ info, show }) => {
  // @TODO DeckCard a11y (focus)
  const [flipped, setFlipped] = React.useState(false)

  if (!show) {
    return null
  }

  const FlipButton = () => {
    return (
      <button
        onClick={() => {
          setFlipped(!flipped)
        }}
        className={cx(
          'flip-card-flip-button absolute right-0 bottom-0',
          'bg-white pt-16 pl-16 pb-24 pr-24 rounded-tl-34',
          'shadow-card hover:shadow-outline focus:shadow-outline'
        )}
      >
        <span className="sr-only">Click/Tap to flip</span>
        <span>
          <FlipIcon className="text-blue-bright" />
        </span>
      </button>
    )
  }

  return (
    <article
      className={cx(
        'flip-card',
        'w-full max-w-deck-card-small min-h-deck-card-small xs:max-w-deck-card xs:min-h-deck-card',
        {
          'flip-card-flipped': flipped,
        }
      )}
    >
      <div className={cx('flip-card-content', 'absolute w-full h-full')}>
        {info.displayImage[0] && (
          <figure
            className={cx(
              'flip-card-front',
              'absolute w-full h-full',
              'rounded-t-22 rounded-bl-22 rounded-br-60 overflow-hidden shadow-card'
            )}
          >
            <img
              className="block max-w-deck-card-small min-h-deck-card-small xs:max-w-deck-card xs:min-h-deck-card object-cover"
              src={info.displayImage[0].url}
              alt={`${info.firstName} ${info.lastName}`}
            />
            <FlipButton />
          </figure>
        )}

        <div
          className={cx(
            'flip-card-back',
            'absolute w-full h-full',
            'flex items-center justify-center bg-white',
            'rounded-t-22 rounded-bl-22 rounded-br-60 overflow-hidden shadow-card'
          )}
        >
          <div className="text-center px-14">
            <PersonIcon className="text-blue-bright" />
            <h3 className="hd-lg md:hd-xl text-blue-bright mb-12">
              {info.firstName} {info.lastName}
            </h3>

            <p className="hd-xs uppercase mb-28">{info.role}</p>
            <LinkButton to={`/decks/people/${info.username}`}>
              View More
            </LinkButton>
          </div>
          <FlipButton />
        </div>
      </div>
    </article>
  )
}

const DeckCard = React.memo(DeckCardComponent)

const DeckPage = () => {
  const { deck } = useUserbaseDeckDBState()
  const { updateDeckKnownIds } = useUserbaseDeckDB()
  const [active, setActive] = React.useState(0)
  const [unknownPeople, setUnknownPeople] = React.useState([])

  const instance = useAxios()
  const [state, setState] = React.useState({
    data: { users: [] },
    loading: true,
    error: null,
  })

  const { data: { users = [] } = {}, loading, error } = state

  React.useEffect(() => {
    async function query() {
      try {
        const {
          data: { data },
        } = await instance.request({
          data: PEOPLE_QUERY,
        })

        setState({
          data,
          loading: false,
          error: null,
        })
      } catch (error) {
        setState((prevState) => ({
          ...prevState,
          error: error,
        }))
      }
    }

    query()
  }, [instance])

  React.useEffect(() => {
    if (users.length <= 0) {
      return
    }

    const newUnknownPeople = users.filter(
      (u) => !deck.item.knownIds.includes(u.id)
    )

    const unknownPeopleLength = newUnknownPeople.length

    if (active === unknownPeopleLength && unknownPeopleLength !== 0) {
      setActive(unknownPeopleLength - 1)
    }

    setUnknownPeople(newUnknownPeople)
  }, [users, deck.item.knownIds, active])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>
  }

  const PREV_TOP_OF_DECK = active - 1
  const NEXT_BOTTOM_OF_DECK = active + 3

  return (
    <>
      <SEO title="Test you Knowledge" />

      <section className="relative max-w-deck-card-small xs:max-w-deck-card mx-auto">
        <div className="md:absolute md:flex md:flex-col md:items-center md:top-0 md:right-full -mt-80 mb-80 md:m-0 md:pr-40 space-y-20">
          <Link
            to="/"
            className="antialiased whitespace-no-wrap text-white no-underline hd-md"
          >
            <AngleLeftIcon /> Change Deck
          </Link>
          <ul className="hidden md:block list-none text-white text-lg space-y-20 p-0">
            <li>
              <UserFriendsIcon className="mr-12" />
              {users.length}
            </li>
            <li>
              <KnownCheckIcon className="mr-12" />
              {users.length - unknownPeople.length}
            </li>
            <li>
              <CardsIcon className="mr-12" /> {unknownPeople.length}
            </li>
          </ul>
          {/* @TODO come back to shuffle and reset in v2 */}
          {/* <AltButton
            className="block items-center"
            onClick={() => {
              console.log('shuffle')
            }}
          >
            <RandomIcon className="mr-8" />
            Shuffle
          </AltButton>
          <AltButton
            className="block items-center"
            onClick={() => {
              console.log('Reset')
            }}
          >
            <UndoIcon className="mr-8" />
            Reset
          </AltButton> */}
        </div>

        <div
          className={cx('flip-card-container flex flex-wrap', {
            'flip-card-container-first': active == 0,
          })}
        >
          {unknownPeople.map((user, index) => {
            return (
              <DeckCard
                key={user.id}
                info={user}
                show={index >= PREV_TOP_OF_DECK && index <= NEXT_BOTTOM_OF_DECK}
              />
            )
          })}
          {unknownPeople.length <= 0 ? (
            <div className="flex flex-col items-center justify-center bg-white w-full rounded-34">
              <p className="mb-12">You know everyone</p>
              <LinkButton to="/decks/edit">Add People</LinkButton>
            </div>
          ) : null}
        </div>
      </section>

      {unknownPeople.length > 0 ? (
        <div className="flex justify-center items-center space-x-24">
          <button
            className={cx(
              'antialiased relative flex items-center justify-center h-48 w-48',
              'bg-white text-blue-bright text-center',
              'border-2 border-white rounded-full',
              'hocus:shadow-button',
              'active:border-white active:shadow-inset'
            )}
            onClick={async () => {
              await updateDeckKnownIds(
                deck.item.knownIds.concat(unknownPeople[active].id)
              )
            }}
          >
            <CheckIcon className="text-blue-bright" />
            <span className="hd-sm absolute block text-white top-full whitespace-no-wrap text-center mt-8">
              Know ‘em
            </span>
          </button>

          <IconButton
            large
            text="Back"
            onClick={() => {
              if (active > 0) {
                setActive(active - 1)
              }
            }}
          >
            <ArrowLeftIcon className="text-blue-bright" />
          </IconButton>
          <IconButton
            large
            text="Next"
            onClick={() => {
              if (active < unknownPeople.length - 1) {
                setActive(active + 1)
              }
            }}
          >
            <ArrowRightIcon className="text-blue-bright" />
          </IconButton>
        </div>
      ) : null}
    </>
  )
}

export default DeckPage
