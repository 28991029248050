import React from 'react'
import { node } from 'prop-types'
import { theme } from '../../../tailwind.config'

const ScrollToLetterContext = React.createContext(undefined)
const SetScrollToLetterContext = React.createContext(undefined)

export const ScrollToLetterProvider = ({ children }) => {
  const [scrollToLetter, setscrollToLetter] = React.useState(null)

  React.useEffect(() => {
    if (scrollToLetter) {
      setscrollToLetter(null)
    }
  }, [scrollToLetter])

  return (
    <ScrollToLetterContext.Provider value={scrollToLetter}>
      <SetScrollToLetterContext.Provider value={setscrollToLetter}>
        {children}
      </SetScrollToLetterContext.Provider>
    </ScrollToLetterContext.Provider>
  )
}

export const useSetScrollToLetter = () => {
  const setScrollToLetter = React.useContext(SetScrollToLetterContext)

  if (setScrollToLetter === undefined) {
    throw new Error(
      'useScrollToLetter must be used within a ScrollToLetterProvider'
    )
  }

  return setScrollToLetter
}

export const useScrollToLetter = (data) => {
  /** @type {React.MutableRefObject<HTMLFormElement & HTMLDivElement>} */
  const scrollToLetterRef = React.useRef(null)
  const scrollToLetter = React.useContext(ScrollToLetterContext)

  if (scrollToLetter === undefined) {
    throw new Error(
      'useScrollToLetter must be used within a ScrollToLetterProvider'
    )
  }

  React.useEffect(() => {
    if (!scrollToLetterRef.current || !scrollToLetter) {
      return
    }

    const el = scrollToLetterRef.current.querySelector(
      `[data-letter=${scrollToLetter}]`
    )

    const headerHeight = parseInt(theme.spacing.header) * 16
    const elRectY = el ? el.getBoundingClientRect().y : headerHeight
    const yScroll = window.scrollY + elRectY - headerHeight

    /**
     * Using this packages since `scroll-behavior: smooth;` is not fully supported:
     * https://www.npmjs.com/package/smoothscroll-polyfill
     */
    window.scrollTo({ top: yScroll, behavior: 'smooth' })
  }, [scrollToLetter])

  const [scrollToLetterItems, letters] = React.useMemo(() => {
    let letters = []
    if (!data) {
      return []
    }

    const scrollToLetterItems = data.users.map((user) => {
      const letter = user.username.charAt(0)
      if (letters.includes(letter)) {
        return user
      } else {
        letters = letters.concat(letter)
        return {
          ...user,
          letter,
        }
      }
    })

    return [scrollToLetterItems, letters]
  }, [data])

  return {
    scrollToLetterItems,
    letters,
    scrollToLetterRef,
  }
}

ScrollToLetterProvider.propTypes = {
  children: node,
}
