import React from 'react'
import { string } from 'prop-types'
import { useQuery } from '@apollo/client'
import { navigate } from '@reach/router'

import { theme } from '../../../tailwind.config'

import SEO from '../../components/seo'
import { Button } from '../../components/button'

import PersonIcon from '../../images/svg/person-icon.inline.svg'

import { PERSON_QUERY } from '../../domains/people'
import { Loading } from '../../components/loading'
import { useAxios } from '../../services/api'

const PersonPage = ({ username }) => {
  // const { data, loading, error } = useQuery(PERSON_QUERY, {
  //   variables: { username: username },
  // })

  const instance = useAxios()
  const [dataState, setDataState] = React.useState({
    data: null,
    loading: true,
    error: null,
  })

  const { data, loading, error } = dataState

  React.useEffect(() => {
    async function query() {
      try {
        const {
          data: { data },
        } = await instance.request({
          data: PERSON_QUERY(username),
        })

        setDataState({
          data,
          loading: false,
          error: null,
        })
      } catch (error) {
        setDataState((prevState) => ({
          ...prevState,
          error: error,
        }))
      }
    }

    query()
  }, [instance, username])

  React.useEffect(() => {
    const root = document.documentElement
    root.style.setProperty('--contextual-text', theme.colors.blue.bright)
    root.style.setProperty('--contextual-bg', theme.colors.white)
    root.style.setProperty('--contextual-bg-size', '0 0') // hide body bg image

    return () => {
      root.style.setProperty('--contextual-text', theme.colors.white)
      root.style.setProperty('--contextual-bg', theme.colors.blue.bright)
      root.style.setProperty('--contextual-bg-size', 'auto')
    }
  }, [])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>
  }

  const { user } = data

  return (
    <div className="max-w-page-narrow mx-auto">
      <SEO title={user.fullName} />

      <PersonIcon className="block mx-auto mb-12" />

      <header className="text-center mb-24">
        <h1 className="hd-xl text-blue-bright mb-14">{user.fullName}</h1>
        <p className="hd-xs uppercase">{user.role}</p>
      </header>

      <div
        className="inner-html text-center mb-48"
        dangerouslySetInnerHTML={{ __html: user.bio }}
      />

      <Button
        onClick={() => {
          // @TODO make sure this is possible all the time
          navigate(-1)
        }}
        className="block min-w-200"
      >
        Close
      </Button>
    </div>
  )
}

export default PersonPage

PersonPage.propTypes = {
  username: string,
}
