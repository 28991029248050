import React from 'react'
import cx from 'classnames'
import { array } from 'prop-types'
import { useSetScrollToLetter } from '../../../services/scroll-to'

const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('')

export const AlphabetNav = ({ letters }) => {
  const setScrollToLetter = useSetScrollToLetter()

  return (
    <nav className="scrollbar-hack overflow-scroll pb-40">
      <ul className="antialiased flex list-none p-0 -mx-12">
        {alphabet.map((letter) => {
          const includesLetter = letters.includes(letter)
          return (
            <li key={letter}>
              <button
                onClick={() => setScrollToLetter(letter)}
                disabled={!includesLetter}
                className={cx('hd-md px-12', {
                  'text-white': includesLetter,
                  'text-white-18 pointer-events-none': !includesLetter,
                })}
              >
                {letter.toUpperCase()}
              </button>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

AlphabetNav.propTypes = {
  letters: array.isRequired,
}
