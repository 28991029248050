import React from 'react'
import { object } from 'prop-types'
import cx from 'classnames'
import { Link } from 'gatsby'

export const LinkListCard = ({ user }) => {
  return (
    <Link
      to={`/decks/people/${user.username}`}
      className={cx(
        'relative block h-0 aspect-ratio-4/5',
        'rounded overflow-hidden bg-blue-bright shadow-button',
        'hocus:shadow-outline'
      )}
      key={user.id}
    >
      {user.displayImage[0] ? (
        <img
          className="absolute w-full h-full object-cover"
          src={user.displayImage[0].url}
          alt=""
        />
      ) : (
        <div className="absolute w-full h-full bg-white-18" />
      )}
    </Link>
  )
}

LinkListCard.propTypes = {
  user: object.isRequired,
}
