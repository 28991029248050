// @ts-nocheck
const plugins = require('@viget/tailwindcss-plugins')
const a = require('@viget/tailwindcss-plugins/utilities/alpha')
const {
  remPair,
  em,
  rem,
  pxPair,
} = require('@viget/tailwindcss-plugins/utilities/fns')

module.exports = {
  target: 'browserslist',
  prefix: '',
  important: false,
  separator: ':',
  purge: [`./src/**/*.js`],
  future: {
    purgeLayersByDefault: true,
    removeDeprecatedGapUtilities: true,
  },
  theme: {
    screens: {
      xs: em(480),
      sm: em(640),
      md: em(944),
      lg: em(1024),
      xl: em(1280),
      'xs-d': { max: em(479) },
      'sm-d': { max: em(639) },
      'md-d': { max: em(943) },
      'lg-d': { max: em(1023) },
      'xl-d': { max: em(1279) },
    },
    colors: {
      transparent: 'transparent',

      black: '#000',
      white: '#fff',
      'white-18': `#ffffff${a[18]}`,
      'white-80': `#ffffff${a[80]}`,

      gray: {
        muted: '#363636',
      },
      red: {
        hot: '#fd4430',
      },
      yellow: {
        wild: '#ffc30a',
      },
      blue: {
        organic: '#1a1d86',
        bright: '#2a28d3',
        soft: '#459ae9',
      },
      purple: {
        inviting: '#faf5ff',
      },
      pink: {
        warm: '#ff5d97',
      },
    },
    spacing: {
      px: '1px',
      0: '0',
      ...remPair(4),
      ...remPair(8),
      ...remPair(10),
      ...remPair(11), // button match form heights
      ...remPair(12),
      ...remPair(14),
      ...remPair(16),
      ...remPair(20),
      ...remPair(24),
      ...remPair(28),
      ...remPair(32),
      ...remPair(40),
      ...remPair(48),
      ...remPair(60),
      ...remPair(80),
      ...remPair(100),
      ...remPair(120),
      header: rem(83),
    },
    backgroundColor: (theme) => ({
      ...theme('colors'),
      contextual: `var(--contextual-bg)`,
    }),
    backgroundOpacity: (theme) => theme('opacity'),
    backgroundPosition: {
      bottom: 'bottom',
      center: 'center',
      'center-bottom': 'center bottom',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top',
    },
    backgroundSize: {
      auto: 'auto',
      cover: 'cover',
      contain: 'contain',
    },
    borderColor: (theme) => ({
      ...theme('colors'),
      default: theme('colors.gray.muted', 'currentColor'),
    }),
    borderOpacity: (theme) => theme('opacity'),
    borderRadius: {
      none: '0',
      default: rem(20),
      full: '9999px',
      ...remPair(3),
      ...remPair(8),
      ...remPair(13),
      ...remPair(22),
      ...remPair(34),
      ...remPair(50),
      ...remPair(60),
    },
    borderWidth: {
      default: '1px',
      0: '0',
      ...pxPair(2),
      ...pxPair(4),
      ...pxPair(8),
    },
    boxShadow: {
      default: '0px 4px 6px rgba(0, 0, 0, 0.15)',
      card: '0px 0px 20px rgba(0, 0, 0, 0.25)',
      button: '0px 6px 6px 4px rgba(0, 0, 0, 0.25)',
      outline: '0 0 0 3px rgba(66, 153, 225, 0.5)',
      white: '0 0 0 3px rgba(255, 255, 255, 1)',
      inset: 'inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25)',
      none: 'none',
    },
    container: {},
    cursor: {
      auto: 'auto',
      default: 'default',
      pointer: 'pointer',
      wait: 'wait',
      text: 'text',
      move: 'move',
      'not-allowed': 'not-allowed',
    },
    divideColor: (theme) => theme('borderColor'),
    divideOpacity: (theme) => theme('borderOpacity'),
    divideWidth: (theme) => theme('borderWidth'),
    fill: {
      current: 'currentColor',
    },
    flex: {
      1: '1 1 0%',
      auto: '1 1 auto',
      initial: '0 1 auto',
      none: 'none',
    },
    flexGrow: {
      0: '0',
      default: '1',
    },
    flexShrink: {
      0: '0',
      default: '1',
    },
    fontFamily: {
      sans: [
        'geomanist',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        '"Noto Sans"',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: ['Georgia', 'Cambria', '"Times New Roman"', 'Times', 'serif'],
      mono: [
        'Menlo',
        'Monaco',
        'Consolas',
        '"Liberation Mono"',
        '"Courier New"',
        'monospace',
      ],
    },
    fontSize: {
      jb: rem(30),
      xl: rem(24),
      lg: rem(20),
      md: rem(18),
      sm: rem(16),
      xs: rem(14),
      ...remPair(12),
    },
    heading: {
      jb: {
        size: rem(64),
        weight: 'bold',
      },
      xl: {
        size: rem(44),
        weight: 'bold',
      },
      lg: {
        size: rem(34),
      },
      md: {
        size: rem(20),
      },
      sm: {
        size: rem(16),
      },
      xs: {
        size: rem(14),
        letterSpacing: '10',
      },
    },
    utility: {
      sm: {
        size: rem(14),
        letterSpacing: '3',
      },
      xs: {
        size: rem(12),
        letterSpacing: '12',
      },
      input: {
        size: rem(16),
        letterSpacing: '12',
      },
      label: {
        size: rem(12),
        weight: 'semibold',
        letterSpacing: '8',
      },
    },
    fontWeight: {
      normal: '400',
      semibold: '600',
      bold: '700',
      black: '900',
    },
    gap: (theme) => theme('spacing'),
    gridTemplateColumns: {
      none: 'none',
      1: 'repeat(1, minmax(0, 1fr))',
      2: 'repeat(2, minmax(0, 1fr))',
      3: 'repeat(3, minmax(0, 1fr))',
      4: 'repeat(4, minmax(0, 1fr))',
      5: 'repeat(5, minmax(0, 1fr))',
      6: 'repeat(6, minmax(0, 1fr))',
      7: 'repeat(7, minmax(0, 1fr))',
      8: 'repeat(8, minmax(0, 1fr))',
      9: 'repeat(9, minmax(0, 1fr))',
      10: 'repeat(10, minmax(0, 1fr))',
      11: 'repeat(11, minmax(0, 1fr))',
      12: 'repeat(12, minmax(0, 1fr))',
    },
    gridColumn: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
      'span-7': 'span 7 / span 7',
      'span-8': 'span 8 / span 8',
      'span-9': 'span 9 / span 9',
      'span-10': 'span 10 / span 10',
      'span-11': 'span 11 / span 11',
      'span-12': 'span 12 / span 12',
    },
    gridColumnStart: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
    },
    gridColumnEnd: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
      8: '8',
      9: '9',
      10: '10',
      11: '11',
      12: '12',
      13: '13',
    },
    gridTemplateRows: {
      none: 'none',
      1: 'repeat(1, minmax(0, 1fr))',
      2: 'repeat(2, minmax(0, 1fr))',
      3: 'repeat(3, minmax(0, 1fr))',
      4: 'repeat(4, minmax(0, 1fr))',
      5: 'repeat(5, minmax(0, 1fr))',
      6: 'repeat(6, minmax(0, 1fr))',
    },
    gridRow: {
      auto: 'auto',
      'span-1': 'span 1 / span 1',
      'span-2': 'span 2 / span 2',
      'span-3': 'span 3 / span 3',
      'span-4': 'span 4 / span 4',
      'span-5': 'span 5 / span 5',
      'span-6': 'span 6 / span 6',
    },
    gridRowStart: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
    },
    gridRowEnd: {
      auto: 'auto',
      1: '1',
      2: '2',
      3: '3',
      4: '4',
      5: '5',
      6: '6',
      7: '7',
    },
    height: (theme) => ({
      auto: 'auto',
      ...theme('spacing'),
      full: '100%',
      screen: '100vh',
      input: rem(49),
    }),
    aspectRatio: {
      none: 0,
      '4/5': [4, 5],
    },
    inset: {
      0: '0',
      '1/2': '50%',
      full: '100%',
      auto: 'auto',
    },
    letterSpacing: {
      normal: '0',
      3: '0.03em',
      8: '0.08em',
      10: '0.1em',
      12: '0.12em',
    },
    lineHeight: {
      none: '1',
      normal: '1.15',
      loose: '1.4',
    },
    listStyleType: {
      none: 'none',
      disc: 'disc',
      decimal: 'decimal',
    },
    margin: (theme, { negative }) => ({
      auto: 'auto',
      ...theme('spacing'),
      ...negative(theme('spacing')),
    }),
    maxHeight: {
      full: '100%',
      screen: '100vh',
    },
    maxWidth: {
      full: '100%',
      container: rem(1024),
      page: rem(944),
      'page-narrow': rem(548),
      form: rem(408),
      'deck-card-small': rem(300),
      'deck-card': rem(332),
      ...remPair(200),
      ...remPair(90),
    },
    minHeight: {
      0: '0',
      full: '100%',
      screen: '100vh',
      'deck-card-small': rem(331),
      'deck-card': rem(366),
    },
    minWidth: {
      0: '0',
      full: '100%',
      ...remPair(200),
      ...remPair(312),
    },
    objectPosition: {
      bottom: 'bottom',
      center: 'center',
      left: 'left',
      'left-bottom': 'left bottom',
      'left-top': 'left top',
      right: 'right',
      'right-bottom': 'right bottom',
      'right-top': 'right top',
      top: 'top',
    },
    opacity: {
      0: '0',
      25: '0.25',
      50: '0.5',
      75: '0.75',
      100: '1',
    },
    order: {
      first: '-9999',
      last: '9999',
      none: '0',
    },
    padding: (theme) => theme('spacing'),
    placeholderColor: (theme) => theme('colors'),
    placeholderOpacity: (theme) => theme('opacity'),
    rect: {
      'deck-initials': [40, 40],
      'eye-icon': [27, 18],
      'eye-icon-sm': [16, 11],
      'hidden-sm': [18, 15],
      'folksy-bubble-sm': [300, 311],
      'folksy-bubble-lg': [465, 480],
      checkbox: [21, 21],
      logo: [64, 64],
      loading: [40, 40],
    },
    rotate: {
      90: '90deg',
      180: '180deg',
    },
    scale: {
      0: '0',
      100: '1',
    },
    skew: {},
    space: (theme, { negative }) => ({
      ...theme('spacing'),
      ...negative(theme('spacing')),
    }),
    stroke: {
      current: 'currentColor',
    },
    textColor: (theme) => ({
      ...theme('colors'),
      contextual: 'var(--contextual-text)',
    }),
    textOpacity: (theme) => theme('opacity'),
    transformOrigin: {
      center: 'center',
      top: 'top',
      'top-right': 'top right',
      right: 'right',
      'bottom-right': 'bottom right',
      bottom: 'bottom',
      'bottom-left': 'bottom left',
      left: 'left',
      'top-left': 'top left',
    },
    transitionDelay: (theme) => theme('transitionDuration'),
    transitionDuration: {
      200: '200ms',
      400: '400ms',
      500: '500ms',
    },
    transitionProperty: {
      none: 'none',
      default: 'all',
      opacity: 'opacity',
      transform: 'transform',
    },
    transitionTimingFunction: {
      'in-out': 'cubic-bezier(0.420, 0.000, 0.580, 1.000)',
    },
    translate: (theme, { negative }) => ({
      ...theme('spacing'),
      ...negative(theme('spacing')),
      '-full': '-100%',
      '-1/2': '-50%',
      '1/2': '50%',
      full: '100%',
    }),
    width: (theme) => ({
      auto: 'auto',
      ...theme('spacing'),
      '1/2': '50%',
      '1/3': '33.333333%',
      '2/3': '66.666667%',
      '1/4': '25%',
      '2/4': '50%',
      '3/4': '75%',
      '1/5': '20%',
      '2/5': '40%',
      '3/5': '60%',
      '4/5': '80%',
      '1/6': '16.666667%',
      '2/6': '33.333333%',
      '3/6': '50%',
      '4/6': '66.666667%',
      '5/6': '83.333333%',
      '1/12': '8.333333%',
      '2/12': '16.666667%',
      '3/12': '25%',
      '4/12': '33.333333%',
      '5/12': '41.666667%',
      '6/12': '50%',
      '7/12': '58.333333%',
      '8/12': '66.666667%',
      '9/12': '75%',
      '10/12': '83.333333%',
      '11/12': '91.666667%',
      full: '100%',
      screen: '100vw',
      ...remPair(90),
    }),
    zIndex: {
      auto: 'auto',
      bottom: '0',
      'deck-bottom': '1',
      'deck-middle': '2',
      'deck-top': '3',
      'deck-above': '4',
      top: '100',
      modal: '150',
    },
  },
  variants: {
    alignContent: ['responsive'],
    alignItems: ['responsive'],
    alignSelf: ['responsive'],
    appearance: ['responsive'],
    backgroundAttachment: ['responsive'],
    backgroundColor: ['responsive', 'hover', 'focus', 'hocus'],
    backgroundOpacity: ['responsive', 'hover', 'focus', 'hocus'],
    backgroundPosition: ['responsive'],
    backgroundRepeat: ['responsive'],
    backgroundSize: ['responsive'],
    borderCollapse: ['responsive'],
    borderColor: ['responsive', 'hover', 'focus', 'hocus', 'active'],
    borderOpacity: ['responsive', 'hover', 'focus', 'hocus'],
    borderRadius: ['responsive'],
    borderStyle: ['responsive'],
    borderWidth: ['responsive', 'hover'],
    boxShadow: ['responsive', 'hover', 'focus', 'hocus', 'active'],
    clear: ['responsive'],
    cursor: ['responsive'],
    display: ['responsive', 'group-hover', 'group-focus'],
    divideColor: ['responsive'],
    divideOpacity: ['responsive'],
    divideWidth: ['responsive'],
    fill: ['responsive'],
    flex: ['responsive'],
    flexDirection: ['responsive'],
    flexGrow: ['responsive'],
    flexShrink: ['responsive'],
    flexWrap: ['responsive'],
    float: ['responsive'],
    fontFamily: ['responsive'],
    fontSize: ['responsive'],
    fontSmoothing: ['responsive'],
    fontStyle: ['responsive'],
    fontWeight: ['responsive', 'hover', 'focus', 'hocus'],
    gap: ['responsive'],
    gridAutoFlow: ['responsive'],
    gridTemplateColumns: ['responsive'],
    gridColumn: ['responsive'],
    gridColumnStart: ['responsive'],
    gridColumnEnd: ['responsive'],
    gridTemplateRows: ['responsive'],
    gridRow: ['responsive'],
    gridRowStart: ['responsive'],
    gridRowEnd: ['responsive'],
    height: ['responsive'],
    inset: ['responsive'],
    justifyContent: ['responsive'],
    letterSpacing: ['responsive'],
    lineHeight: ['responsive'],
    listStylePosition: ['responsive'],
    listStyleType: ['responsive'],
    margin: ['responsive'],
    maxHeight: ['responsive'],
    maxWidth: ['responsive'],
    minHeight: ['responsive'],
    minWidth: ['responsive'],
    objectFit: ['responsive'],
    objectPosition: ['responsive'],
    opacity: ['responsive', 'hover', 'focus', 'hocus'],
    order: ['responsive'],
    outline: ['responsive', 'focus'],
    overflow: ['responsive'],
    padding: ['responsive'],
    placeholderColor: ['responsive', 'focus'],
    placeholderOpacity: ['responsive', 'focus'],
    pointerEvents: ['responsive'],
    position: ['responsive'],
    rect: ['responsive'],
    resize: ['responsive'],
    rotate: [],
    scale: ['responsive'],
    skew: [],
    space: ['responsive'],
    stroke: ['responsive'],
    strokeWidth: ['responsive'],
    tableLayout: ['responsive'],
    textAlign: ['responsive'],
    textColor: ['responsive', 'hover', 'focus', 'hocus'],
    textDecoration: ['responsive', 'hover', 'focus', 'hocus'],
    textOpacity: ['responsive', 'hover', 'focus', 'hocus'],
    textTransform: ['responsive'],
    transformOrigin: [],
    transitionDelay: ['responsive'],
    transitionDuration: ['responsive'],
    transitionProperty: ['responsive'],
    transitionTimingFunction: ['responsive'],
    translate: ['responsive'],
    userSelect: ['responsive'],
    verticalAlign: ['responsive'],
    visibility: ['responsive'],
    whitespace: ['responsive'],
    width: ['responsive'],
    wordBreak: ['responsive'],
    zIndex: ['responsive'],
    sr: ['group-focus', 'responsive'],
    heading: ['responsive'],
  },
  corePlugins: {
    accessibility: false,
    boxSizing: false,
    container: false,
    preflight: false,
  },
  plugins: [
    plugins.rect,
    plugins.sr,
    require('./config/tailwindcss/heading'),
    require('./config/tailwindcss/utility'),
    require('tailwindcss-interaction-variants'),
    require('tailwindcss-aspect-ratio'),
  ],
}
