import React from 'react'
import { bool, func, object } from 'prop-types'
import cx from 'classnames'

import HiddenIcon from '../../../images/svg/hidden-icon.inline.svg'
import EyeIcon from '../../../images/svg/eye-icon.inline.svg'
import { check } from 'prettier'

export const CheckboxListCard = ({ user, onChange, checked }) => {
  return (
    <div className="checkbox-card">
      <input
        className="sr-only"
        checked={checked}
        type="checkbox"
        id={user.username}
        value={user.id}
        onChange={onChange}
      />

      <label
        className={cx(
          'group relative block h-0 aspect-ratio-4/5 rounded overflow-hidden bg-blue-bright shadow-button cursor-pointer',
          {
            'hocus:shadow-white': !checked,
            'hocus:shadow-outline': checked,
          }
        )}
        htmlFor={user.username}
      >
        {user.displayImage[0] ? (
          <img
            className={cx('absolute w-full h-full object-cover z-bottom', {
              'opacity-50': checked,
            })}
            src={user.displayImage[0].url}
            alt=""
          />
        ) : (
          <div
            className={cx('absolute w-full h-full bg-white-18 z-bottom', {
              'opacity-50': checked,
            })}
          />
        )}
        <div
          className={cx(
            'absolute z-bottom bg-white-80 rounded-full',
            'items-center justify-center w-48 h-48',
            'top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2',
            {
              flex: check,
              'hidden group-hover:flex group-focus:flex': !checked,
            }
          )}
        >
          {checked ? (
            <HiddenIcon className="text-blue-bright rect-eye-icon" />
          ) : (
            <EyeIcon className="text-blue-bright rect-eye-icon" />
          )}
        </div>
      </label>
    </div>
  )
}

CheckboxListCard.propTypes = {
  user: object.isRequired,
  onChange: func.isRequired,
  checked: bool.isRequired,
}
