import React from 'react'
import { navigate } from 'gatsby'

import SEO from '../../components/seo'
import { Form } from '../../components/form'
import { Button } from '../../components/button'
import { Input } from '../../components/input'

import { useUserbaseDeckDB, useUserbaseUser } from '../../services/userbase'
import { useUserbaseDeckDBState } from '../../services/userbase/deckDBProvider'

/**
 * @TODO (phase II)
 * - set deck.item.uri and deck.item.uri token here
 * - wait for the data from the query to become available
 * - then addDeck
 * - then redirect after doing some more work (like setting counts)
 */
const NewDeckPage = () => {
  const { decks } = useUserbaseDeckDBState()
  const { setUserDeck } = useUserbaseUser()
  const { addDeck } = useUserbaseDeckDB()

  const [submittedName, setSubmittedName] = React.useState('')
  const [errorName, setErrorName] = React.useState('')
  const [name, setName] = React.useState('')
  const [uri, setUri] = React.useState(process.env.GATSBY_CRAFT_URL)
  const [token, setToken] = React.useState(process.env.GATSBY_CRAFT_TOKEN)

  const existingDeckNames = decks.map((deck) => deck.item.name)
  const addedDeck = decks.find((deck) => deck.item.name === submittedName)

  /**
   * This is a bit circular but what is happening is:
   * - handleSubmit calls addDeck which updates `decks`
   * - handleSubmit sets a submittedName
   * - we find the submittedName in our updated `decks`
   * - that's the deck we just add it so we `setUserDeck` to that id
   * - redirect to `/decks/view` with this deck selected
   */
  React.useEffect(() => {
    if (!addedDeck) {
      return
    }

    setUserDeck(addedDeck.itemId)
    navigate('/decks/view')
  }, [addedDeck, setUserDeck])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (existingDeckNames.includes(name)) {
      setErrorName(name)
      return
    }

    if (!uri || !token || !name) {
      return
    }

    await addDeck({ name, uri, token })

    if (decks.length === 0) {
      navigate('/decks/view')
    } else {
      setSubmittedName(name)
    }
  }

  return (
    <>
      <SEO title="Add a Deck" />

      <Form className="mx-auto" onSubmit={handleSubmit}>
        <h1 className="hd-xl text-blue-bright mb-24">Add a Deck</h1>

        <Input
          className="mb-24"
          id="name"
          value={name}
          setValue={setName}
          error={
            errorName && errorName === name
              ? `the name ${errorName} is already in use`
              : null
          }
        >
          Name
        </Input>

        <Input className="mb-24" id="uri" value={uri} setValue={setUri}>
          Uri
        </Input>

        <Input
          className="mb-24"
          id="token"
          type="password"
          value={token}
          setValue={setToken}
        >
          Token
        </Input>

        <Button
          className="block min-w-200 mb-32"
          disabled={!name || !uri || !token}
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </>
  )
}

export default NewDeckPage
