import React from 'react'
import cx from 'classnames'
import { bool, func, node, string } from 'prop-types'

import { ClearButton } from '../../../components/button'
import { Input } from '../../../components/input'

import PencilIcon from '../../../images/svg/pencil-icon.inline.svg'
import { useUserbaseDeckDB } from '../../../services/userbase'

export const ListHeading = ({ children, className, edit }) => {
  const inputRef = React.useRef(null)
  const { updateDeckName } = useUserbaseDeckDB()
  const [editing, setEditing] = React.useState(false)
  const [newName, setNewName] = React.useState('')

  React.useEffect(() => {
    const input = inputRef.current

    if (input) {
      input.focus()
      input.select()
    }
  }, [editing])

  const handleEditToggle = () => {
    if (!editing) {
      setNewName(children)
      setEditing(true)
    } else {
      updateDeckName(newName, () => {
        setEditing(false)
      })
    }
  }

  if (edit) {
    return (
      <div
        className={cx(
          className,
          'antialiased md:flex md:items-center md:justify-center'
        )}
      >
        {editing ? (
          <Input
            className="md:mr-8 md-d:mb-12"
            id="name"
            ref={inputRef}
            setValue={setNewName}
            value={newName}
          >
            Name
          </Input>
        ) : (
          <h1 className="hd-xl text-white md:mr-8 md-d:mb-8 md-d:text-center">
            Edit “{children}”
          </h1>
        )}
        <ClearButton
          onClick={handleEditToggle}
          className="flex items-center md-d:mx-auto"
        >
          <PencilIcon className="mr-8" /> {editing ? 'Save' : 'Rename'}
        </ClearButton>
      </div>
    )
  } else {
    return <h1 className={cx(className, 'hd-xl text-white')}>{children}</h1>
  }
}

ListHeading.propTypes = {
  children: node.isRequired,
  className: string,
  edit: bool,
  handleRename: func,
}
