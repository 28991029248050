import React from 'react'
import { Router } from '@reach/router'

import Layout from '../components/layout'
import PrivateRoute from '../components/private-route'

import ListPage from '../client-pages/decks/show'
import EditPage from '../client-pages/decks/edit'
import DeckPage from '../client-pages/decks/deck'
import PersonPage from '../client-pages/card/show'
import NewDeckPage from '../client-pages/decks/new'
import NotFound from '../client-pages/404.js'

const DeckApp = () => {
  return (
    <Layout>
      <Router className="w-full shadow-none">
        <PrivateRoute path="/decks/new" component={NewDeckPage} />
        <PrivateRoute deckReq path="/decks/study" component={DeckPage} />
        <PrivateRoute deckReq path="/decks/view" component={ListPage} />
        <PrivateRoute deckReq path="/decks/edit" component={EditPage} />
        <PrivateRoute
          deckReq
          path="/decks/people/:username"
          component={PersonPage}
        />
        <PrivateRoute component={NotFound} default />
      </Router>
    </Layout>
  )
}

export default DeckApp
