import React from 'react'
import { object } from 'prop-types'

import { AlphabetNav } from '../shared/alphabet-nav'
import { LinkListCard } from './link-list-card'

import { useScrollToLetter } from '../../../services/scroll-to'

export const DeckList = ({ data }) => {
  const { scrollToLetterItems, letters, scrollToLetterRef } = useScrollToLetter(
    data
  )

  return (
    <>
      <AlphabetNav letters={letters} />

      <div ref={scrollToLetterRef} className="flex flex-wrap -mx-12 md:-mx-24">
        {scrollToLetterItems.map((user) => {
          return (
            <div
              data-letter={user.letter || null}
              className="w-1/2 sm:w-1/4 px-12 mb-24 md:px-24 md:mb-48"
              key={user.id}
            >
              <LinkListCard user={user} />
            </div>
          )
        })}
      </div>
    </>
  )
}

DeckList.propTypes = {
  data: object,
}
