import React from 'react'
import cx from 'classnames'
import { navigate } from 'gatsby'

import SEO from '../../../components/seo'
import { AltButton, ClearButton } from '../../../components/button'
import { ListHeading } from '../shared/list-heading'
import { DeckEditList } from './deck-edit-list'

import SaveIcon from '../../../images/svg/save-icon.inline.svg'
import HiddenIcon from '../../../images/svg/hidden-icon.inline.svg'
import EyeIcon from '../../../images/svg/eye-icon.inline.svg'
import CardsIcon from '../../../images/svg/cards-icon.inline.svg'

import { PEOPLE_QUERY } from '../../../domains/people'
import { useUserbaseDeckDB } from '../../../services/userbase'
import { ScrollToLetterProvider } from '../../../services/scroll-to'
import { useUserbaseDeckDBState } from '../../../services/userbase/deckDBProvider'
import { Loading } from '../../../components/loading'
import { useAxios } from '../../../services/api'

/**
 * @param {import('./types').DeckEditState} state
 * @param {import('./types').DeckEditAction} action
 */
function reducer(state, action) {
  switch (action.type) {
    case 'add':
      return { knownIds: state.knownIds.concat(action.payload) }
    case 'remove':
      return { knownIds: state.knownIds.filter((id) => id !== action.payload) }
    case 'reset':
      return { knownIds: action.payload }
    default:
      throw new Error()
  }
}

const ShowHideAllButton = ({ children, onClick }) => {
  return (
    <button
      className={cx(
        'flex items-center',
        'text-white text-center',
        'bg-blue-organic',
        'border-2 border-blue-organic rounded-34',
        'hocus:bg-blue-bright',
        'active:border-white active:shadow-inset',
        'py-4 px-16 util-sm leading-loose'
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const EditPage = () => {
  const { updateDeckKnownIds } = useUserbaseDeckDB()
  const { deck } = useUserbaseDeckDBState()
  const [state, dispatch] = React.useReducer(reducer, {
    knownIds: deck.item.knownIds,
  })

  const instance = useAxios()
  const [dataState, setDataState] = React.useState({
    data: null,
    loading: true,
    error: null,
  })

  const { data, loading, error } = dataState

  React.useEffect(() => {
    async function query() {
      try {
        const {
          data: { data },
        } = await instance.request({
          data: PEOPLE_QUERY,
        })

        setDataState({
          data,
          loading: false,
          error: null,
        })
      } catch (error) {
        setDataState((prevState) => ({
          ...prevState,
          error: error,
        }))
      }
    }

    query()
  }, [instance])

  if (loading) {
    return <Loading />
  }

  if (error) {
    return <p>{error.message}</p>
  }

  const userIds = data.users.map((user) => user.id)

  const validKnownIds = deck
    ? state.knownIds.filter((knownId) => userIds.includes(knownId))
    : []

  const totalCards = deck ? deck.item.cardCount : 0
  const hiddenCards = deck ? validKnownIds.length : 0

  const handleSave = (e) => {
    if (e) {
      e.preventDefault()
    }

    updateDeckKnownIds(state.knownIds).then(() => {
      navigate('/decks/view')
    })
  }

  const handleReset = () => {
    dispatch({ type: 'reset', payload: deck.item.knownIds })
    navigate('/decks/view')
  }

  const handleSelection = (e) => {
    if (!e.target) {
      return
    }

    if (state.knownIds.includes(e.target.value)) {
      dispatch({ type: 'remove', payload: e.target.value })
    } else {
      dispatch({ type: 'add', payload: e.target.value })
    }
  }

  return (
    <section className="max-w-page mx-auto">
      <SEO title="Peruse" />

      <header className="md:flex md:justify-between items-center mb-16">
        <ListHeading className="md-d:mb-12 md:mr-12" edit>
          {deck.item.name}
        </ListHeading>

        <div className="flex flex-shrink-0 items-center justify-center md:ml-12">
          <ClearButton className="mr-16" onClick={handleReset}>
            Cancel
          </ClearButton>
          <AltButton className="flex items-baseline" onClick={handleSave}>
            <SaveIcon className="mr-8" /> Save Changes
          </AltButton>
        </div>
      </header>

      <section className="sm:flex sm:justify-between sm:items-center mb-24">
        <div className="flex items-center md-d:justify-center">
          <span className="flex items-center text-white util-sm mr-24">
            <CardsIcon className="mr-12" /> {totalCards} Cards
          </span>
          <span className="flex items-center text-white util-sm">
            <HiddenIcon className="rect-hidden-sm mr-12" />
            {hiddenCards} Hidden
          </span>
        </div>

        <div className="flex items-center space-x-8 sm-d:justify-center sm-d:pt-24">
          <ShowHideAllButton
            onClick={() => {
              dispatch({ type: 'reset', payload: [] })
            }}
          >
            <EyeIcon className="rect-eye-icon-sm mr-8" />
            show all
          </ShowHideAllButton>
          <ShowHideAllButton
            onClick={() => {
              dispatch({ type: 'reset', payload: userIds })
            }}
          >
            <HiddenIcon className="rect-hidden-sm mr-8" /> hide all
          </ShowHideAllButton>
        </div>
      </section>

      <ScrollToLetterProvider>
        <DeckEditList
          data={data}
          handleSave={handleSave}
          handleSelection={handleSelection}
          knownIds={state.knownIds}
        />
      </ScrollToLetterProvider>
    </section>
  )
}

export default EditPage
