const plugin = require('tailwindcss/plugin')

module.exports = plugin(({ addUtilities, theme, variants }) => {
  addUtilities(
    Object.entries(theme('utility')).map(([name, props]) => {
      const { size, letterSpacing = 'normal', weight = 'bold' } = props

      const rule = {
        'font-family': theme('fontFamily.sans').join(', '),
        'font-size': size,
        'font-weight': theme(`fontWeight.${weight}`),
        'letter-spacing': theme(`letterSpacing.${letterSpacing}`),
      }

      return {
        [`.util-${name}`]: rule,
      }
    }),
    variants('utility', [])
  )
})
